import { debounce, removeTrapFocus, trapFocus, pauseAllMedia } from '../js/global-exports.js';

/**
 * Safari check needed, because Safari does not show images that are ajax-loadet in a good quality
 * @returns true if browser is Safari, otherwise it returns false
 */
function detectSafari() {
  let safariAgent = false;
  // Detect Safari
  if(navigator.userAgent) {
      safariAgent = navigator.userAgent.indexOf('Safari') > -1;

      let chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;

      // Discard Safari since it also matches Chrome
      if ((chromeAgent) && (safariAgent)) {
          safariAgent = false;
      }
  }
  window.browserIsSafari = safariAgent;
}
detectSafari();



if(document.getElementById('OnlineStoreEditorShopifyGlobalData')) {
  document.body.classList.add('body--visual-editor')
}

function openCartInNewWindowWhenInIframe() {
    if(window.self !== window.top) {
    document.addEventListener('click',(e)=>{
        const classNames = ['header__icon--cart', 'button--cart-notification'];
        classNames.some(className => {
          return e.target.classList.contains(className)
        });
      if(classNames.some(className => e.target.classList.contains(className))) {
        e.preventDefault();
        const cartIframeModalDetails = document.getElementById('cart-iframe-modal-details');
        if(cartIframeModalDetails) {
          cartIframeModalDetails.querySelector('summary').click();
        }
      }
    })
  }
}
openCartInNewWindowWhenInIframe();

/**
* call this function if you want to close an overlay that uses the
* overflow-hidden class on the body element. Here you can check if
* other elements that use that class are open - if they are, do not
* remove the overflow-hidden class on body
*
* IMPORTANT: close the overlay details element itself before calling
* this function since it might check the very element you want to
* close too
* @returns
*/

function otherOpenOverlaysWithBodyLock(elementBeingClosed) {
  // header drawer
  if(!elementBeingClosed || elementBeingClosed.tagName.toLowerCase() !== 'header-drawer') {
    const headerDrawer = document.querySelector('header-drawer');
    if(headerDrawer) {
      if(headerDrawer.querySelector(':scope > details').open === true) {
        return true;
      }
    }
  }

  // predictive search
  const predictiveSearchModal = document.querySelector('.header__search > details');
  if(predictiveSearchModal) {
    if(predictiveSearchModal.open === true) {
      return true;
    }
  }

  //details modal
  const detailsModal = document.querySelector('details-modal');
  if(detailsModal) {
    if(detailsModal.querySelector(':scope > details').open === true) {
      return true;
    }
  }

  //modal-dialog
  const modalDialog = document.querySelector('modal-dialog');
  if(modalDialog && modalDialog.classList.contains('js-cart-product-modal') && modalDialog.hasAttribute('open')) {
      return true;
  }

  return false;
}

// Here run the querySelector to figure out if the browser supports :focus-visible or not and run code based on it.
try {
  document.querySelector(':focus-visible');
} catch(error) {
  focusVisiblePolyfill();
}

function focusVisiblePolyfill() {
  const navKeys = ['ARROWUP', 'ARROWDOWN', 'ARROWLEFT', 'ARROWRIGHT', 'TAB', 'ENTER', 'SPACE', 'ESCAPE', 'HOME', 'END', 'PAGEUP', 'PAGEDOWN']
  let currentFocusedElement = null;
  let mouseClick = null;

  window.addEventListener('keydown', (event) => {
    if(navKeys.includes(event.code.toUpperCase())) {
      mouseClick = false;
    }
  });

  window.addEventListener('mousedown', () => {
    mouseClick = true;
  });

  window.addEventListener('focus', () => {
    if (currentFocusedElement) currentFocusedElement.classList.remove('focused');

    if (mouseClick) return;

    currentFocusedElement = document.activeElement;
    currentFocusedElement.classList.add('focused');

  }, true);
}

class QuantityInput extends HTMLElement {
  constructor() {
    super();
    this.input = this.querySelector('input');
    this.changeEvent = new Event('change', { bubbles: true })
    if(window.isB2bSite === true) {
      this.onCard = this.closest('.card-wrapper');
      if(this.onCard) {
        this.stopEventBubbling();
      }
    }

    this.querySelectorAll('button').forEach(
      (button) => button.addEventListener('click', this.onButtonClick.bind(this))
    );
  }

  stopEventBubbling(){
    this.closest('.card-wrapper').addEventListener('click',(e)=>{
      if(this.contains(e.target)) {
        e.preventDefault();
      }
    })
  }

  onButtonClick(event) {
    event.preventDefault();
    const previousValue = this.input.value;

    event.target.name === 'plus' ? this.input.stepUp() : this.input.stepDown();
    if (previousValue !== this.input.value) this.input.dispatchEvent(this.changeEvent);
  }
}
customElements.define('quantity-input', QuantityInput);


/*
 * Shopify Common JS
 *
 */
if ((typeof window.Shopify) == 'undefined') {
  window.Shopify = {};
}

Shopify.bind = function(fn, scope) {
  return function() {
    return fn.apply(scope, arguments);
  }
};

Shopify.setSelectorByValue = function(selector, value) {
  for (var i = 0, count = selector.options.length; i < count; i++) {
    var option = selector.options[i];
    if (value == option.value || value == option.innerHTML) {
      selector.selectedIndex = i;
      return i;
    }
  }
};

Shopify.addListener = function(target, eventName, callback) {
  target.addEventListener ? target.addEventListener(eventName, callback, false) : target.attachEvent('on'+eventName, callback);
};

Shopify.postLink = function(path, options) {
  options = options || {};
  var method = options['method'] || 'post';
  var params = options['parameters'] || {};

  var form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  for(var key in params) {
    var hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', key);
    hiddenField.setAttribute('value', params[key]);
    form.appendChild(hiddenField);
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

Shopify.CountryProvinceSelector = function(country_domid, province_domid, options) {
  this.countryEl         = document.getElementById(country_domid);
  this.provinceEl        = document.getElementById(province_domid);
  this.provinceContainer = document.getElementById(options['hideElement'] || province_domid);

  Shopify.addListener(this.countryEl, 'change', Shopify.bind(this.countryHandler,this));

  this.initCountry();
  this.initProvince();
};

Shopify.CountryProvinceSelector.prototype = {
  initCountry: function() {
    var value = this.countryEl.getAttribute('data-default');
    Shopify.setSelectorByValue(this.countryEl, value);
    this.countryHandler();
  },

  initProvince: function() {
    var value = this.provinceEl.getAttribute('data-default');
    if (value && this.provinceEl.options.length > 0) {
      Shopify.setSelectorByValue(this.provinceEl, value);
    }
  },

  countryHandler: function() {
    let opt       = this.countryEl.options[this.countryEl.selectedIndex];
    const raw       = opt.getAttribute('data-provinces');
    const provinces = JSON.parse(raw);

    this.clearOptions(this.provinceEl);
    if (provinces && provinces.length == 0) {
      this.provinceContainer.style.display = 'none';
    } else {
      for (var i = 0; i < provinces.length; i++) {
        opt = document.createElement('option');
        opt.value = provinces[i][0];
        opt.innerHTML = provinces[i][1];
        this.provinceEl.appendChild(opt);
      }

      this.provinceContainer.style.display = '';
    }
  },

  clearOptions: function(selector) {
    while (selector.firstChild) {
      selector.removeChild(selector.firstChild);
    }
  },

  setOptions: function(selector, values) {
    for (var i = 0; i < values.length; i++) {
      var opt = document.createElement('option');
      opt.value = values[i];
      opt.innerHTML = values[i];
      selector.appendChild(opt);
    }
  }
};

Shopify.formatMoney = function(argCents, format) {
    let cents = argCents;
    if (typeof cents === 'string') {
      cents = cents.replace('.', '');
    }
    let value = '';
    const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
    const formatString = format || window.moneyFormat;

    function formatWithDelimiters(argNumber, precision, argThousands, argDecimal) {
      let thousands = argThousands;
      let decimal = argDecimal;
      let number = argNumber;
      thousands = thousands || ',';
      decimal = decimal || '.';

      if (Number.isNaN(number) || number === null) {
        return 0;
      }

      number = (number / 100.0).toFixed(precision);

      const parts = number.split('.');
      const dollarsAmount = parts[0].replace(
        /(\d)(?=(\d\d\d)+(?!\d))/g,
        `$1${thousands}`
      );
      const centsAmount = parts[1] ? decimal + parts[1] : '';

      return dollarsAmount + centsAmount;
    }

    switch (formatString.match(placeholderRegex)[1]) {
      case 'amount_no_decimals':
        value = formatWithDelimiters(cents, 0);
        break;
      case 'amount_with_comma_separator':
        value = formatWithDelimiters(cents, 2, '.', ',');
        break;
      case 'amount_no_decimals_with_comma_separator':
        value = formatWithDelimiters(cents, 0, '.', ',');
        break;
      case 'amount_no_decimals_with_space_separator':
        value = formatWithDelimiters(cents, 0, ' ');
        break;
      case 'amount_with_apostrophe_separator':
        value = formatWithDelimiters(cents, 2, '\'');
        break;
      case 'amount':
      default:
        value = formatWithDelimiters(cents, 2);
        break;
    }

    return formatString.replace(placeholderRegex, value);
  }

function videoStoppingOverlayIsOpen(){
  let videoStoppingOverlayIsOpen = false;
  if(document.querySelector('details.menu-drawer-container.menu-opening[open]')) {
      videoStoppingOverlayIsOpen = true;
    }
  if(document.querySelector('details-disclosure-mega-menu>details[open]')) {
    videoStoppingOverlayIsOpen = true;
  }
  return videoStoppingOverlayIsOpen;
}
class MenuDrawer extends HTMLElement {
  constructor() {
    super();

    this.mainDetailsToggle = this.querySelector('details');
    const summaryElements = this.querySelectorAll('summary');
    this.addAccessibilityAttributes(summaryElements);

    if (navigator.platform === 'iPhone') document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);

    this.addEventListener('keyup', this.onKeyUp.bind(this));
    this.addEventListener('focusout', this.onFocusOut.bind(this));
    this.bindEvents();
    this.stickyHeader = document.querySelector('sticky-header');
  }

  bindEvents() {
    this.querySelectorAll('summary').forEach(summary => summary.addEventListener('click', this.onSummaryClick.bind(this)));
    this.querySelectorAll('button').forEach(button => button.addEventListener('click', this.onCloseButtonClick.bind(this)));
  }

  addAccessibilityAttributes(summaryElements) {
    summaryElements.forEach(element => {
      element.setAttribute('role', 'button');
      element.setAttribute('aria-expanded', false);
      element.setAttribute('aria-controls', element.nextElementSibling.id);
    });
  }

  onKeyUp(event) {
    if(event.code.toUpperCase() !== 'ESCAPE') return;

    const openDetailsElement = event.target.closest('details[open]');
    if(!openDetailsElement) return;

    openDetailsElement === this.mainDetailsToggle ? this.closeMenuDrawer(this.mainDetailsToggle.querySelector('summary')) : this.closeSubmenu(openDetailsElement);
  }

  onSummaryClick(event) {
    const summaryElement = event.currentTarget;
    const detailsElement = summaryElement.parentNode;
    const isOpen = detailsElement.hasAttribute('open');

    if (detailsElement === this.mainDetailsToggle) {
      if(isOpen) event.preventDefault();
      isOpen ? this.closeMenuDrawer(summaryElement) : this.openMenuDrawer(summaryElement);
    } else {
      trapFocus(summaryElement.nextElementSibling, detailsElement.querySelector('button'));

      setTimeout(() => {
        detailsElement.classList.add('menu-opening');
      });
    }
  }

  openMenuDrawer(summaryElement) {
    setTimeout(() => {
      this.mainDetailsToggle.classList.add('menu-opening');
    });
    summaryElement.setAttribute('aria-expanded', true);
    trapFocus(this.mainDetailsToggle, summaryElement);
    document.body.classList.add('overflow-hidden');
  }

  backToTransparentOnTouchDevices() {
    const isTransparentHeader = document.body.classList.contains('body--header-transparent');
    if(isTransparentHeader) {

      var deviceType = window.getComputedStyle(document.body, ':after').getPropertyValue('content');
      if(deviceType === '"touch"') {
        var stickyHeader = document.querySelector('sticky-header');
        stickyHeader.checkIfBackgroundShouldBeTransparent('burgerMenuOnTouch');
      }
    }
  }

  closeMenuDrawer(event, elementToFocus = false) {
    if(this.headerDrawerIsOpen) {
      this.headerDrawerIsOpen = false;
    }
    if (event !== undefined) {
      this.mainDetailsToggle.classList.remove('menu-opening');
      this.mainDetailsToggle.querySelectorAll('details').forEach(details =>  {
        details.removeAttribute('open');
        details.classList.remove('menu-opening');
      });
      this.mainDetailsToggle.querySelector('summary').setAttribute('aria-expanded', false);
      removeTrapFocus(elementToFocus);
      this.closeAnimation(this.mainDetailsToggle);
    }

    this.mainDetailsToggle.classList.remove('menu-drawer-container--show-active');
    document.dispatchEvent(new CustomEvent('restartBackgroundVideos', { bubbles: true }));
    this.stickyHeader.checkIfBackgroundShouldBeTransparent();
    if(this.tagName.toLowerCase() === 'header-drawer') {
      this.backToTransparentOnTouchDevices();
    }
  }

  onFocusOut() {
    setTimeout(() => {
      if (this.mainDetailsToggle.hasAttribute('open') && !this.mainDetailsToggle.contains(document.activeElement)) this.closeMenuDrawer();
    });
  }

  onCloseButtonClick(event) {
    const detailsElement = event.currentTarget.closest('details');
    this.closeSubmenu(detailsElement);
  }

  closeSubmenu(detailsElement) {
    detailsElement.classList.remove('menu-opening');
    removeTrapFocus();
    this.closeAnimation(detailsElement);
  }

  closeAnimation(detailsElement) {
    let animationStart;

    const handleAnimation = (time) => {
      if (animationStart === undefined) {
        animationStart = time;
      }

      const elapsedTime = time - animationStart;

      if (elapsedTime < 400) {
        window.requestAnimationFrame(handleAnimation);
      } else {
        detailsElement.removeAttribute('open');
        if(!otherOpenOverlaysWithBodyLock(this)) {
          document.body.classList.remove('overflow-hidden');
        }
        if (detailsElement.closest('details[open]')) {
          trapFocus(detailsElement.closest('details[open]'), detailsElement.querySelector('summary'));
        }
        this.stickyHeader.checkIfBackgroundShouldBeTransparent();
      }
    }

    window.requestAnimationFrame(handleAnimation);
  }
}

customElements.define('menu-drawer', MenuDrawer);

class HeaderDrawer extends MenuDrawer {
  constructor() {
    super();
    this.getDrawerFooterFromTemplate();
    this.headerDrawerIsOpen = false;
    this.summaryElement = this.querySelector('[aria-controls="menu-drawer"]');

    this.menuDrawerBackdrop = document.getElementById('menu-drawer__backdrop');
    if(this.menuDrawerBackdrop) {
      this.menuDrawerBackdrop.addEventListener('click', (e) => {this.closeMenuDrawer(e);});
    }

    this.addClickOutSideEvents(this,this.closeMenuDrawer.bind(this))
  }

  addClickOutSideEvents(elementToWatch, callbackFunction) {
    document.addEventListener('click', (event) => {this.clickOutSide(event,elementToWatch,callbackFunction)});
    document.addEventListener('focus', (event) => {this.clickOutSide(event,elementToWatch,callbackFunction)});
  }

  clickOutSide(event, elementToWatch, callbackFunction) {
    // if element is outside and is not the mobile facets opener button
    if (!elementToWatch.contains(event.target) && event.target.id !== 'js-mobile-facets__open') {
      callbackFunction(event);
    }
  }

  getDrawerFooterFromTemplate() {
    const templ = document.getElementById('template_menu_drawer_footer');
    const footerHolder = document.getElementById('menu-drawer-footer');
    if(templ && footerHolder) {
      const fragment = templ.content;
      footerHolder.prepend(fragment);
    }
  }

  openMenuDrawer() {
    pauseAllMedia();
    this.headerDrawerIsOpen = true;
    this.header = this.header || document.getElementById('shopify-section-header');
    this.borderOffset = this.borderOffset || this.closest('.header-wrapper').classList.contains('header-wrapper--border-bottom') ? 1 : 0;
    if(this.header.classList.contains('header-opacity-holder-section--hidden') && this.header.classList.contains('header-opacity-holder-section--sticky')) {
      this.header.classList.remove('header-opacity-holder-section--hidden');
    }
    document.documentElement.style.setProperty('--header-bottom-position', `${parseInt(this.header.getBoundingClientRect().bottom - this.borderOffset)}px`);

    setTimeout(() => {
      this.mainDetailsToggle.classList.add('menu-opening');
      this.mainDetailsToggle.open = true;
    });

    if(this.summaryElement) {
      this.summaryElement.setAttribute('aria-expanded', true);
    }
    trapFocus(this.mainDetailsToggle, this.summaryElement);

    document.body.classList.add('overflow-hidden');
  }

  openSpecificNavigationItem() {
    this.openMenuDrawer();
    const currentPage = this.querySelector('a.menu-drawer__menu-item--active');
    if(currentPage) {
      let parentDetails = currentPage.closest('details');
      while (parentDetails && !parentDetails.classList.contains('menu-drawer-container')) {
          parentDetails.classList.add('menu-opening');
          parentDetails.open = true;
          const parentDetailsParent = parentDetails.parentElement;
          parentDetails = parentDetailsParent.closest('details');
      }
      if(parentDetails.classList.contains('menu-drawer-container')) {
        parentDetails.classList.add('menu-drawer-container--show-active')
      }
    }
  }
}

customElements.define('header-drawer', HeaderDrawer);

class ModalDialog extends HTMLElement {
  constructor() {
    super();
    this.querySelector('[id^="ModalClose-"]').addEventListener(
      'click',
      this.hide.bind(this)
    );
    this.addEventListener('keyup', (event) => {
      if (event.code.toUpperCase() === 'ESCAPE') this.hide();
    });
    if (this.classList.contains('media-modal')) {
      this.addEventListener('pointerup', (event) => {
        if (event.pointerType === 'mouse' && !event.target.closest('deferred-media, product-model')) this.hide();
      });
    } else {
      this.addEventListener('click', (event) => {
        if (event.target.nodeName === 'MODAL-DIALOG') this.hide();
      });
    }
  }

  show(opener) {
    this.openedBy = opener;
    const popup = this.querySelector('.template-popup');
    if (!this.classList.contains('collage-video__modal--localization-modal')){
      document.body.classList.add('overflow-hidden');
    }
    this.setAttribute('open', '');
    if (popup) popup.loadContent();
    trapFocus(this, this.querySelector('[role="dialog"]'));
    if(this.id !== 'PopupModal-location') {
      pauseAllMedia();
    }
  }

  hide() {
      document.body.classList.remove('overflow-hidden');
    this.removeAttribute('open');
    removeTrapFocus(this.openedBy);
    if(this.id !== 'PopupModal-location') {
      pauseAllMedia();
      document.dispatchEvent(new CustomEvent('restartBackgroundVideos', { bubbles: true }));
    }
  }
}
customElements.define('modal-dialog', ModalDialog);

class ProductModal extends ModalDialog {
  constructor() {
    super();
  }

  hide() {
    super.hide();
  }

  show(opener) {
    super.show(opener);
    this.showActiveMedia();
  }

  showActiveMedia() {
    this.querySelectorAll(`[data-media-id]:not([data-media-id="${this.openedBy.getAttribute('data-media-id')}"])`).forEach((element) => {
        element.classList.remove('active');
      }
    )
    const activeMedia = this.querySelector(`[data-media-id="${this.openedBy.getAttribute('data-media-id')}"]`);
    const activeMediaTemplate = activeMedia.querySelector('template');
    const activeMediaContent = activeMediaTemplate ? activeMediaTemplate.content : null;
    activeMedia.classList.add('active');
    activeMedia.scrollIntoView();

    const container = this.querySelector('[role="document"]');
    container.scrollLeft = (activeMedia.width - container.clientWidth) / 2;

    if (activeMedia.nodeName == 'DEFERRED-MEDIA' && activeMediaContent && activeMediaContent.querySelector('.js-youtube'))
      activeMedia.loadContent();
  }
}

customElements.define('product-modal', ProductModal);

class ModalOpener extends HTMLElement {
  constructor() {
    super();

    const button = this.querySelector('button');
    if (!button) return;
    button.addEventListener('click', () => {
      const modal = document.querySelector(this.getAttribute('data-modal'));
      if (modal) modal.show(button);
    });
  }
}
customElements.define('modal-opener', ModalOpener);

class DeferredMedia extends HTMLElement {
  constructor() {
    super();
    const poster = this.querySelector('[id^="Deferred-Poster-"]');
    if (!poster) return;
    poster.addEventListener('click', this.loadContent.bind(this));
    poster.addEventListener('startLoadingVideo', (e) => {this.loadContent(e)});
    // remember it must this.loadContent, but not start playing until you swipe to it
    // can you stop it from loading, if card scrolls out of screen or another video starts loading?
  }

  loadContent(e) {
    pauseAllMedia();
    if (!this.getAttribute('loaded')) {
      const content = document.createElement('div');

      let videoHTML = this.querySelector('template').content.firstElementChild.cloneNode(true);

      //if is PDP video, do not autoplay
      if(this.closest('.js-slider-component-product-detail')) {
        videoHTML.removeAttribute('autoplay');
      }

      content.appendChild(videoHTML);

      this.setAttribute('loaded', true);
      if(e && e.detail && e.detail === 'preload') {
        const videoEl = content.querySelector('video');
        if(videoEl) {
          videoEl.load();
          videoEl.pause();//pause video immediately or it will "jump" when you slide to it
        }
        this.appendChild(content.querySelector('video, model-viewer, iframe'));
      } else {
        this.appendChild(content.querySelector('video, model-viewer, iframe')).focus();
        //to make it autoplay on iphone:
        const insertedVideo = this.querySelector('video');
        if(insertedVideo) {
          insertedVideo.play()
        }
      }

    }
  }
}

customElements.define('deferred-media', DeferredMedia);

if (!customElements.get('product-model')) {
  customElements.define('product-model', class ProductModel extends DeferredMedia {
    constructor() {
      super();
    }

    loadContent() {
      super.loadContent();

      Shopify.loadFeatures([
        {
          name: 'model-viewer-ui',
          version: '1.0',
          onLoad: this.setupModelViewerUI.bind(this)
        }
      ]);
    }

    setupModelViewerUI(errors) {
      if (errors) return;

      this.modelViewerUI = new Shopify.ModelViewerUI(this.querySelector('model-viewer'));
    }
  });
}
class SliderNavigation extends HTMLElement {
  constructor() {
    super();
    this.activeClass = 'product__media-navigation-list-item--selected';
    this.navigationFor = this.nextSibling;
    this.slider =this.querySelector('.product__media-navigation-list');
    this.elems =this.getElementsByClassName('product__media-navigation-list-item');
    this.elemsInfo=[];
    this.btns = this.getElementsByClassName('product__media-navigation-button');
    this.upButton = this.querySelector('button[name="previous"]');
    this.downButton = this.querySelector('button[name="next"]');
    this.currentPage = 0;
    this.observerRoot = document.querySelector('.product__media-navigation-list');
    if(!this.observerRoot) return;
    for (let i = 0; i < this.btns.length; i++) {
      const btn = this.btns[i];
      btn.addEventListener('click', this.onButtonClick.bind(this));
    }

    for (let j = 0; j < this.elems.length; j++) {
      const elem = this.elems[j];
      this.trackMediaBtnVisibility(elem,j);
      this.elemsInfo.push({'fullyShown':false,'partlyShown':false})
    }
    this.upButton.addEventListener('click', this.onUpDownButtonClick.bind(this));
    this.downButton.addEventListener('click', this.onUpDownButtonClick.bind(this));
    this.slider.scrollTop = 0;
    setTimeout(() => {this.classList.remove('slider-navigation__hidden')},350);
  }

  updateUpDownBtnVisibility() {
    const firstElemInfo = this.elemsInfo[0];
    const hideClass = 'hidden';

    if(firstElemInfo.fullyShown) {
      this.upButton.classList.add(hideClass)
    } else {
      this.upButton.classList.remove(hideClass)
    }
    const lastElemInfo = this.elemsInfo[this.elemsInfo.length -1];
    if(lastElemInfo.fullyShown) {
      this.downButton.classList.add(hideClass)
    } else {
      this.downButton.classList.remove(hideClass)
    }
  }
  trackMediaBtnVisibility(elem,thisIndex) {
    //fully shown
    let options = {
      root: this.observerRoot,
      threshold:0.95 /* because of some bug last item will never be shown completely */
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.elemsInfo[thisIndex].fullyShown = true;
          } else {
            this.elemsInfo[thisIndex].fullyShown = false;
          }
          this.updateUpDownBtnVisibility();
        });
      },
      options
    );
    observer.observe(elem);

    //partly shown
    let options2 = {
      root: this.observerRoot,
      threshold: 0.3
    }


    const observer2 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // this.initAndStartCounter();
            this.elemsInfo[thisIndex].partlyShown = true;
          } else {
            this.elemsInfo[thisIndex].partlyShown = false;

          }
          this.updateUpDownBtnVisibility();
        });
      },
      options2
    );
    observer2.observe(elem);

  }
  onUpDownButtonClick(e) {
      const isUpBtn = e.target.classList.contains('slider-navigation__button--prev');
      let scrollToSlideIndex = 0;
      if(isUpBtn) {
        let counter = 0;
        while (!this.elemsInfo[counter].fullyShown) {
          counter++;
        }
        scrollToSlideIndex = counter - 1 < 0 ? 0 :counter-1;
      } else {
        let counter2 = 0;
        while (!this.elemsInfo[counter2].partlyShown) {
          counter2++;
        }
        scrollToSlideIndex = counter2 + 1 > this.elemsInfo.length-1 ? this.elemsInfo.length-1 :counter2+1;
      }
      const elem2scroll2=this.elems[scrollToSlideIndex];

      this.slider.scrollTo({
          top: elem2scroll2.offsetTop,
          behavior: 'smooth'
        })
  }

  setSelectedIndex(slideIndex) {
    const existingCurrent = this.getElementsByClassName(this.activeClass);
    if(existingCurrent.length) {
      existingCurrent[0].classList.remove(this.activeClass);
    }
    this.elems[slideIndex].classList.add(this.activeClass);
  }

  onButtonClick(event) {
    const currentItemElem = event.target.parentElement;
    const currentIndex = Array.prototype.indexOf.call(this.elems, currentItemElem)
    this.navigationFor.goToSlideIndex(currentIndex )
  }
}

customElements.define('slider-navigation', SliderNavigation);

class SliderComponent extends HTMLElement {
  constructor() {
    super();
    this.sliderNavigation = this.previousElementSibling; // image navigation as on product detail page
    this.currentPage = 1;
    this.slider = this.querySelector(':scope > ul.slider');
    this.sliderButtons = this.querySelector(':scope > .slider-buttons');
    if (!this.slider || !this.sliderButtons) return;

    this.hasVideoPreload = this.classList.contains('js-slider-component-product-detail') || this.tagName.toLowerCase() === 'slider-component-card';
    if(this.hasVideoPreload) {
      this.sliderVideoPosters = this.querySelectorAll('[id^="Deferred-Poster-"]');

      this.sliderButtons.addEventListener('click',(e)=>{this.clickMaybeDisabled(e);})
      this.slider.addEventListener('scroll', (e)=>{
        if(!this.sliderHasStartedSliding){
          this.clickMaybeDisabled(e);
        }
      });
    }

    this.sliderItems = this.slider.querySelectorAll(':scope > li');
    this.pageCount = this.sliderButtons.querySelector('.slider-counter--current');
    this.pageTotal = this.sliderButtons.querySelector('.slider-counter--total');
    this.prevButton = this.sliderButtons.querySelector('button[name="previous"]');
    this.nextButton = this.sliderButtons.querySelector('button[name="next"]');
    this.isProductPage = false;

    const resizeObserver = new ResizeObserver(() => this.initPages());
    resizeObserver.observe(this.slider);

    this.slider.addEventListener('scroll', debounce(() => {this.update()},150));
    this.prevButton.addEventListener('click', this.onButtonClick.bind(this));
    this.nextButton.addEventListener('click', this.onButtonClick.bind(this));

    this.isProductPage = this.closest('.product__media-wrapper--slider-navigation');

    if(this.sliderNavigation && this.sliderNavigation.tagName.toLowerCase() === 'slider-navigation') {
      this.isProductPage = true;
    }

    const mediaList = document.getElementsByClassName('product__media-list')[0];

    if(mediaList) {
      this.scrollChecker(mediaList)
    }
  }
  goToSlideIndex(slideIndex) {
    const elem2scroll2=this.sliderItemsToShow[slideIndex];
    this.slider.scrollTo({
      left: elem2scroll2.offsetLeft,
      behavior: 'smooth'
    })
  }

  initPages() {
    this.goingToPosition = null;
    this.lastPosition = 0;
    this.lastScrollPosition = 0;
    this.hasStoppedScrolling = true;
    this.lastScrollTime = 0;
    this.timeOut = null;
    this.sliderItemsToShow = Array.from(this.sliderItems).filter(element => element.clientWidth > 0);
    this.sliderLastItem = this.sliderItemsToShow[this.sliderItemsToShow.length - 1];
    if (this.sliderItemsToShow.length === 0) return;
    this.slidesPerPage = Math.floor(this.slider.clientWidth / this.sliderItemsToShow[0].clientWidth);
    this.totalPages = this.sliderItemsToShow.length - this.slidesPerPage + 1;
    if(this.slider.classList.contains('product__media-list--product-detail')) {
      this.totalPages = this.sliderItemsToShow.length;
    }
    this.update();
    if(this.slider.classList.contains('product__media-list--product-detail') || this.slider.classList.contains('card__media-list')) {
      this.autoplayVideoIfAny(0);//start-slide index
    }
  }

  update() {
    if (!this.pageCount || !this.pageTotal) return;
    this.currentPage = Math.round(this.slider.scrollLeft / this.sliderLastItem.clientWidth) + 1;

    if (this.currentPage === 1) {
      this.prevButton.setAttribute('disabled', true);
    } else {
      this.prevButton.removeAttribute('disabled');
    }

    if (this.currentPage === this.totalPages) {
      this.nextButton.setAttribute('disabled', true);
    } else {
      this.nextButton.removeAttribute('disabled');
    }

    this.pageCount.textContent = this.currentPage;
    this.pageTotal.textContent = this.totalPages;
    if(this.slider.classList.contains('product__media-list--product-detail') || this.slider.classList.contains('card__media-list')) {
      if(this.lastPosition !== this.currentPage - 1) {
        this.lastPosition = this.currentPage - 1;
        this.autoplayVideoIfAny(this.currentPage - 1);//slide index
      }
    }
    if(this.isProductPage) {
      this.sliderNavigation.setSelectedIndex(this.currentPage - 1);
    }
    this.cardExtensions();
  }
  cardExtensions() {
    // do nothing in this parent class
  }
  scrollChecker(mediaList) {
    this.debouncedScroll = debounce((e) => {
        var today = new Date();
        this.lastScrollTime = today.getTime();
        if(this.hasStoppedScrolling === true) {
          var el = e.target;
          this.hasStoppedScrolling = false;
          clearTimeout(this.timeOut)
          setTimeout(this.checkStoppedScrolling(el),500)
        }
    }, 150);
    mediaList.addEventListener('scroll', this.debouncedScroll.bind(this));
  }

  checkStoppedScrolling(el) {
    var today = new Date();
    var milliseconds = today.getTime();
    if(milliseconds - this.lastScrollTime < 500) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {this.checkStoppedScrolling(el)},500)
    } else {
      this.lastScrollPosition = this.slider.scrollLeft;
      clearTimeout(this.timeOut)
      this.hasStoppedScrolling = true;
    }
  }

  pauseNonCurrentSliderVideos(currentSlideIndex) {
    const otherVideoSlides = this.querySelectorAll(`:scope > ul > li:not(:nth-child(${parseInt(currentSlideIndex+1, 10)})) video`)
    otherVideoSlides.forEach(video => {
      video.pause();
    });
  }

  autoplayVideoIfAny(slideIndex){
    var slideByIndex = this.slider.getElementsByClassName('product__media-item')[slideIndex];
    if(!slideByIndex) return;
    //only if PDP
    if(this.isProductPage) {
      this.pauseNonCurrentSliderVideos(slideIndex);
    }
    const poster = slideByIndex.querySelector('[id^="Deferred-Poster-"]');
    if (!poster) return;
    pauseAllMedia();
    window.dispatchEvent(new Event('resize'));
    let videoEl = slideByIndex.getElementsByTagName('video')[0];
    if(getComputedStyle(poster).display==='block') {
      setTimeout(() => {this.startVideoFirstTimeIfNotScrolling(poster,slideIndex)},250);
    } else {
      videoEl = slideByIndex.getElementsByTagName('video')[0];
      if (!videoEl) return;
      videoEl.currentTime = 0;
      videoEl.play();
    }
  }
  startVideoFirstTimeIfNotScrolling(poster,slideIndex) {
    if(slideIndex!==this.currentPage -1 ) return;
    if(this.hasStoppedScrolling) {
        poster.parentElement.loadContent();
    } else {
      const self = this;
      setTimeout(function() {self.startVideoFirstTimeIfNotScrolling(poster,slideIndex)},100);
    }
  }
  onButtonClick(event) {
    event.preventDefault();
    //product detail slider
    if(this.slider.classList.contains('product__media-list--product-detail')) {
        const currentPageIndex = this.currentPage -1;
      let scrollToSlide = event.currentTarget.name === 'next' ? currentPageIndex + 1 : currentPageIndex - 1;
      if(scrollToSlide < 0) {
        scrollToSlide = 0;
      }
      if(scrollToSlide >this.totalPages - 1) {
        scrollToSlide = this.totalPages - 1;
      }
      const elem2scroll2=this.sliderItemsToShow[scrollToSlide];

      this.slider.scrollTo({
        left: elem2scroll2.offsetLeft,
        behavior: 'smooth'
      })
    } else {
      const slideScrollPosition = event.currentTarget.name === 'next' ? this.slider.scrollLeft + this.sliderLastItem.clientWidth : this.slider.scrollLeft - this.sliderLastItem.clientWidth;
      this.slider.scrollTo({
        left: slideScrollPosition
      });
    }
  }

  clickMaybeDisabled(e) {
    if(e.target.hasAttribute('disabled') && (e.target === this.prevButton || e.target === this.nextButton)){
    e.stopPropagation();
    e.stopImmediatePropagation();
    return false;
    }else{
      this.startPreloadingVideoIfAny();
    }
  }
  startPreloadingVideoIfAny(){
    if(this.sliderVideoPosters.length && !this.sliderHasStartedSliding){
      const event = new CustomEvent('startLoadingVideo', { detail: 'preload' });
      for (let i = 0; i < this.sliderVideoPosters.length; i++) {
        const element = this.sliderVideoPosters[i];
        element.dispatchEvent(event);
      }
      this.sliderHasStartedSliding = true;
    }
  }
}
customElements.define('slider-component', SliderComponent);

window.SliderComponentCardFirstFoundDesktop = false;
window.SliderComponentCardFirstFoundMobile = false;
class SliderComponentCard extends SliderComponent {
  constructor() {
    super();
    if (!this.slider || !this.sliderButtons) return;
    this.sliderHasStartedSliding = false;

    this.sliderCounterActive = this.querySelector('.slider-counter__graphical-active');
    this.setWidthOfActiveCounter();
    this.isFirstCardDesktop = false;
    this.isFirstCardMobile = false;
    this.initSliderIndication();
  }

  initSliderIndication() {
    if(window.SliderComponentCardFirstFoundDesktop === false) {
      window.SliderComponentCardFirstFoundDesktop = true;
      this.isFirstCardDesktop = true;
      this.animationToIndicateSlider();
    }
    const showSliderOnMobile = this.getAttribute('data-show-slider-on-mobile');
    if(showSliderOnMobile && showSliderOnMobile === 'true') {
      if(window.SliderComponentCardFirstFoundMobile === false) {
        window.SliderComponentCardFirstFoundMobile = true;
        this.isFirstCardMobile = true;
        this.animationToIndicateSlider();
      }
    }
  }

  animationToIndicateSlider() {
    this.classList.add('helper-first-slider-component-card');
    new IntersectionObserver(this.handleIntersection.bind(this), {rootMargin: '0px 0px 200px 0px'}).observe(this);
  }
  handleIntersection(entries, observer) {
    if (!entries[0].isIntersecting) return;
    observer.unobserve(this);

    if(this.isFirstCardMobile){
      this.classList.add('helper-first-slider-component-card-animated-mobile');

    }
    if(this.isFirstCardDesktop) {
      this.classList.add('helper-first-slider-component-card-animated-desktop');
    }

  }
  setWidthOfActiveCounter() {
    const widthPercentage = 1 / this.sliderItems.length * 100;
    this.sliderCounterActive.style.width = `${widthPercentage}%`;
  }

  cardExtensions(){
    this.sliderButtons.classList.remove('hidden');

    // make dots instead of 1/4
    const translatePercentage = (this.currentPage - 1) * 100;
    this.sliderCounterActive.style.transform = `translateX(${translatePercentage}%)`;
    this.totalPages = this.sliderItems.length;
  }

  autoplayVideoIfAny(slideIndex){
    var slideByIndex = this.slider.getElementsByClassName('product__media-item')[slideIndex];
    if(!slideByIndex) return;
    const poster = slideByIndex.querySelector('[id^="Deferred-Poster-"]');
    if (!poster) return;
    window.dispatchEvent(new Event('resize'));
    let videoEl = slideByIndex.getElementsByTagName('video')[0];
    pauseAllMedia();
    if(getComputedStyle(poster).display==='block'){
      setTimeout(()=>{this.startVideoFirstTimeIfNotScrolling(poster,slideIndex)},250);
    } else {
      videoEl = slideByIndex.getElementsByTagName('video')[0];
      if (!videoEl) return;
      videoEl.currentTime = 0;
      videoEl.play();
    }
  }
}
  customElements.define('slider-component-card', SliderComponentCard);

class sliderComponentGridElement extends SliderComponent {
  constructor() {
    super();
    this.initFadeText();
    if(!this.slider || !this.sliderButtons) return;
    if(this.slider.classList.contains('slider--mobile')) {
      const x = window.matchMedia('(max-width: 989px)');
      this.getMatchingBreakPoint(x);

      x.addEventListener('change', () => {
        this.getMatchingBreakPoint(x);
      });
    }

  }

  initFadeText() {
    this.stopFadeButtons = this.querySelectorAll('.grid-component__fade-button-outer');
    for (let i = 0; i < this.stopFadeButtons.length; i++) {
      const stopFadeBtn = this.stopFadeButtons[i];
      stopFadeBtn.addEventListener('click', (e) => {
        let currentParent = e.target;
        while (!currentParent.classList.contains('grid-component__text-block-outer')) {
          currentParent = currentParent.parentElement;
        }
        currentParent.classList.remove('grid-component__text-block-outer--fade-text-mobile','grid-component__text-block-outer--fade-text-desktop');
      })
    }
  }

  getMatchingBreakPoint(x) {
    if (x.matches) {
      if(this.slider) {
        this.slider.style.display='flex';

        const lis = this.slider.getElementsByClassName('grid-component__list-item');
        for(let j = 0; j < lis.length;j+=1) {
          const li = lis[j];
          li.classList.add('grid__item')
        }
      }
    } else {
      if(this.slider) {
        const lis = this.slider.getElementsByClassName('grid-component__list-item');
        this.slider.style.display='';

        for(let j = 0; j < lis.length;j+=1) {
          const li = lis[j];
          li.classList.remove('grid__item')
        }
      }
    }
  }
}
customElements.define('slider-component-grid-element', sliderComponentGridElement);

class VariantSelects extends HTMLElement {
  constructor() {
    super();
    this.addEventListener('change', this.onVariantChange);
  }

  onVariantChange() {
    this.updateOptions();
    this.updateMasterId();
    this.toggleAddButton(true, '', false);
    this.updatePickupAvailability();
    this.removeErrorMessage();

    if (!this.currentVariant) {
      this.toggleAddButton(true, '', true);
      this.setUnavailable();
    } else {
      this.updateMedia();
      this.updateURL();
      this.updateVariantInput();
      this.renderProductInfo();
    }
  }

  updateOptions() {
    this.options = Array.from(this.querySelectorAll('select'), (select) => select.value);
  }

  updateMasterId() {
    this.currentVariant = this.getVariantData().find((variant) => {
      return !variant.options.map((option, index) => {
        return this.options[index] === option;
      }).includes(false);
    });
  }

  updateMedia() {
    if (!this.currentVariant) return;
    if (!this.currentVariant.featured_media) return;
    const newMedia = document.querySelector(
      `[data-media-id="${this.dataset.section}-${this.currentVariant.featured_media.id}"]`
    );

    if (!newMedia) return;
    const modalContent = document.querySelector(`#ProductModal-${this.dataset.section} .product-media-modal__content`);
    const newMediaModal = modalContent.querySelector( `[data-media-id="${this.currentVariant.featured_media.id}"]`);
    const parent = newMedia.parentElement;
    if (parent.firstChild == newMedia) return;
    modalContent.prepend(newMediaModal);
    parent.prepend(newMedia);
    this.stickyHeader = this.stickyHeader || document.querySelector('sticky-header');
    if(this.stickyHeader) {
      this.stickyHeader.dispatchEvent(new Event('preventHeaderReveal'));
    }
    window.setTimeout(() => { parent.querySelector('li.product__media-item').scrollIntoView({behavior: 'smooth'}); });
  }

  updateURL() {
    if (!this.currentVariant || this.dataset.updateUrl === 'false') return;
    window.history.replaceState({ }, '', `${this.dataset.url}?variant=${this.currentVariant.id}`);
  }

  updateVariantInput() {
    const productForms = document.querySelectorAll(`#product-form-${this.dataset.section}, #product-form-installment`);
    productForms.forEach((productForm) => {
      const input = productForm.querySelector('input[name="id"]');
      input.value = this.currentVariant.id;
      input.dispatchEvent(new Event('change', { bubbles: true }));
    });
  }

  updatePickupAvailability() {
    const pickUpAvailability = document.querySelector('pickup-availability');
    if (!pickUpAvailability) return;

    if (this.currentVariant && this.currentVariant.available) {
      pickUpAvailability.fetchAvailability(this.currentVariant.id);
    } else {
      pickUpAvailability.removeAttribute('available');
      pickUpAvailability.innerHTML = '';
    }
  }

  removeErrorMessage() {
    const section = this.closest('section');
    if (!section) return;

    const productForm = section.querySelector('product-form');
    if (productForm) productForm.handleErrorMessage();
  }

  renderProductInfo() {
    fetch(`${this.dataset.url}?variant=${this.currentVariant.id}&section_id=${this.dataset.section}`)
      .then((response) => response.text())
      .then((responseText) => {
        const id = `price-${this.dataset.section}`;
        const html = new DOMParser().parseFromString(responseText, 'text/html')
        const destination = document.getElementById(id);
        const source = html.getElementById(id);

        if (source && destination) destination.innerHTML = source.innerHTML;

        const price = document.getElementById(`price-${this.dataset.section}`);

        if (price) price.classList.remove('visibility-hidden');
        this.toggleAddButton(!this.currentVariant.available, window.variantStrings.soldOut);
      });
  }

  toggleAddButton(disable = true, text, modifyClass = true) {
    const productForm = document.getElementById(`product-form-${this.dataset.section}`);
    if (!productForm) return;
    const addButton = productForm.querySelector('[name="add"]');
    const addButtonText = productForm.querySelector('[name="add"] > span');

    if (!addButton) return;

    if (disable) {
      addButton.setAttribute('disabled', true);
      if (text) addButtonText.textContent = text;
    } else {
      addButton.removeAttribute('disabled');
      addButtonText.textContent = window.variantStrings.addToCart;
    }

    if (!modifyClass) return;
  }

  setUnavailable() {
    const button = document.getElementById(`product-form-${this.dataset.section}`);
    const addButton = button.querySelector('[name="add"]');
    const addButtonText = button.querySelector('[name="add"] > span');
    const price = document.getElementById(`price-${this.dataset.section}`);
    if (!addButton) return;
    addButtonText.textContent = window.variantStrings.unavailable;
    if (price) price.classList.add('visibility-hidden');
  }

  getVariantData() {
    this.variantData = this.variantData || JSON.parse(this.querySelector('[type="application/json"]').textContent);
    return this.variantData;
  }
}
customElements.define('variant-selects', VariantSelects);

class HideTagSlider extends HTMLElement {
  constructor() {
    super();
    const theSection = this.closest('section');
    if(theSection) {
      theSection.style.display = 'none';
    }
  }
}
customElements.define('hide-tag-slider', HideTagSlider);

class VariantRadios extends VariantSelects {
  constructor() {
    super();
  }

  updateOptions() {
    const fieldsets = Array.from(this.querySelectorAll('fieldset'));
    this.options = fieldsets.map((fieldset) => {
      return Array.from(fieldset.querySelectorAll('input')).find((radio) => radio.checked).value;
    });
  }
}

customElements.define('variant-radios', VariantRadios);


class BackgroundVideo extends HTMLElement {
  constructor() {
    super();
    this.bgVideo = this.querySelector('video');
    this.sectionInViewport= false;
    this.videoPlayPromise = null;
    const options = { threshold: 0 };
    if(this.bgVideo) {
      const backgroundObserver = new IntersectionObserver(this.observeSectionIntersection.bind(this), options);
        backgroundObserver.observe(this.bgVideo);
    }
    this.initSoundControl();
    document.addEventListener('restartBackgroundVideos',()=>{this.restartBackgroundVideosAfterOverlayClose();})
  }

  initSoundControl() {
    const parentLink = this.closest('.grid-component__item-link');
    if(!parentLink) {return;}
    parentLink.addEventListener('click',e=> {
      const clickedBtn = e.target;
      if(clickedBtn.classList.contains('button-unmute')) {
        if(this.bgVideo.muted) {
          this.bgVideo.muted = false;
          clickedBtn.classList.add('button-unmute--sound-is-on');
        } else {
          this.bgVideo.muted = true;
          clickedBtn.classList.remove('button-unmute--sound-is-on');
        }
        e.preventDefault();
      }
    })
  }

  restartBackgroundVideosAfterOverlayClose() {
    if(!videoStoppingOverlayIsOpen()) {
      this.restartVideoBackgroundObserver = new IntersectionObserver(this.observeSectionIntersection.bind(this), { threshold: 0 });
      this.restartVideoBackgroundObserver.observe(this.bgVideo);
    }
  }

  observeSectionIntersection(entries) {
    entries.forEach((entry) => {
    const maybePause = this.restartVideoBackgroundObserver ? 'unpause' : null;
    if (entry.isIntersecting && !videoStoppingOverlayIsOpen()) {
      this.startVideo(maybePause);
    } else {
      this.stopVideo();
    }
    if(maybePause) {
      this.restartVideoBackgroundObserver.unobserve(this.bgVideo);
    }
    });
  }

  async startVideo(task) {
    if (this.bgVideo) {
      try {
        if(!task || task !== 'unpause') {
          this.bgVideo.currentTime = 0;
        }
        this.videoPlayPromise = await this.bgVideo.play();
      } catch (err) {
        /**
         * safari will probably go here, since it can't start playing video with
         * js if it has no autoplay attribute or has been interacted with by the
         * user. But can't add autoplay to all videos since there can be multiple
         * videos pr page and no need to load them all if not needed (adding the
         * autoplay attribute will make them start downloading too).
         */
        if(!this.bgVideo.hasAttribute('autoplay')) {

          const newBgVideo = this.cloneNode(true);
          const newBgVideoVideo = newBgVideo.querySelector('video');
          if(newBgVideoVideo) {

            newBgVideoVideo.setAttribute('autoplay','autoplay');
            this.after(newBgVideo);
            this.remove();
          }
        }
      }
    }
  }
  stopVideo() {
    if (this.bgVideo && this.videoPlayPromise != null) {
      this.videoPlayPromise.then(() => {
        this.bgVideo.pause();
        this.videoPlayPromise = null;
      });
    }
  }
}

customElements.define('background-video', BackgroundVideo);




class LoadMoreButton extends HTMLElement {
  constructor() {
    super();
    this.btn = this.querySelector('button');

    if (!this.btn ) return;

    this.btn.addEventListener('click', this.loadMore.bind(this));
    const isOnIOS = navigator.userAgent.match(/Safari/i);
    if(isOnIOS){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const page = urlParams.get('page')
      const itemsPerPage = document.getElementById('ProductGridContainer').dataset.pageSize;
      const itemsOnPage = document.querySelectorAll('#ProductGridContainer .grid__item').length;
      if(itemsPerPage){
        if(itemsOnPage <= itemsPerPage * page - 1){
          localStorage.setItem('reloadCollectionList', true);
          location.reload();
        }
      }
    }
  }

  updateURL(nextUrl) {
    if (!nextUrl) return;
    window.history.replaceState({ }, '', `${nextUrl}`);
  }

  loadMore(event) {
    event.preventDefault();

    const btn =event.target;

    btn.setAttribute('disabled', true);
    const nextUrl = btn.querySelector('[data-next-url]').value;
    this.updateURL(nextUrl);

    fetch(nextUrl)
    .then((response) => response.text())
    .then((responseText) => {
      const id = 'ProductGridContainer';
      const html = new DOMParser().parseFromString(responseText, 'text/html')
      const destination = document.getElementById(id).querySelector('.product-grid');
      const source = html.getElementById(id).querySelector('.product-grid');

      let items = source.querySelectorAll('.grid__item:not(.grid__item--temp):not(.product__media-item)');

      const newBtn = html.querySelector('load-more-button');
      if (source && destination) {
        for(let i = 0;i < items.length;i +=1) {
          const item = items[i];

          //add class so that safari only needs to repaint ajax-loadet images
          const images = item.getElementsByTagName('img');
          for (let i = 0; i < images.length; i++) {
            const element = images[i];
            element.classList.add('ajax-loadet');
          }

          destination.appendChild(item);
        }
      }
      if(newBtn) {
        this.parentNode.replaceChild(newBtn, this);
      } else {
        this.classList.add('hidden')
      }

      // inserted ads update
      const existingAdsFirstItem = destination.querySelector('.inserted-ad');
      const existingStyleTags = destination.querySelectorAll('style');
      for (let j = 0; j < existingStyleTags.length; j++) {
        const element = existingStyleTags[j];
        element.remove();
      }

      const newStyleTags = source.querySelectorAll('style');
      for (let k = 0; k < newStyleTags.length; k++) {
        const element = newStyleTags[k];
        existingAdsFirstItem.before(element);
      }

      // so that wishlist can update items on newly loaded pages
      if(window.currentWishlist) {
        const event = new CustomEvent('newCollectionPageLoaded',{detail:{wishlistPage: false, allVariantIds: window.currentWishlist.allVariantIds}});
        document.dispatchEvent(event);
      }

		if(window.isB2bSite === true) {
        const startEvent = new CustomEvent('startLoadingCollectionPrices', {
          bubbles:true,
          detail: {
            value: id,
            type: 'id'
          }
        });
        document.dispatchEvent(startEvent);
      }
    });
  }
}
customElements.define('load-more-button', LoadMoreButton);


class LoadPrevPages extends HTMLElement {
  constructor() {
    super();
    const pages = parseInt(this.getAttribute('data-load-pages'),10);
    this.pagesTotal = pages + 1;
    this.nextUrlBasic = `${window.location.pathname}${window.location.search}`
    this.countPagesLoadet = 1;
    for(let i = 1;i<=pages;i+=1) {
      this.loadPage(i)
    }
  }

  startLoadingCollectionPrices() {
    const startEvent = new CustomEvent('startLoadingCollectionPrices', {
      bubbles:true,
      detail: {
        value: 'ProductGridContainer',
        type: 'id'
      }
    });
    document.dispatchEvent(startEvent);
  }

  loadPage(pageNo) {
    const nextUrl = this.nextUrlBasic.replace(/page=[0-9]+/,'page='+pageNo);

    fetch(nextUrl)
    .then((response) => response.text())
    .then((responseText) => {
      const id = 'ProductGridContainer';
      const html = new DOMParser().parseFromString(responseText, 'text/html')
      const destination = document.getElementById(id).querySelector('.product-grid');
      const source = html.getElementById(id).querySelector('.product-grid');
      let items = source.querySelectorAll(':scope > .grid__item:not(.grid__item--temp)');
      if (source && destination) {
        for(let i = 0;i < items.length;i +=1) {
          const item = items[i];
          const fakeItem = destination.querySelector('.grid__item--temp-page-'+pageNo);

          //add class so that safari only needs to repaint ajax-loadet images
          const images = item.getElementsByTagName('img');
          for (let i = 0; i < images.length; i++) {
              const element = images[i];
              element.classList.add('ajax-loadet');
          }

          fakeItem.parentNode.replaceChild(item, fakeItem);
        }

        if(window.isB2bSite === true) {
          this.countPagesLoadet++;
          if(this.countPagesLoadet === this.pagesTotal) {
            this.startLoadingCollectionPrices();

            // so that wishlist can update items on newly loaded pages
            if(window.currentWishlist) {
              const event = new CustomEvent('newCollectionPageLoaded',{detail:{wishlistPage: false, allVariantIds: window.currentWishlist.allVariantIds}});
              document.dispatchEvent(event);
            }
          }
        }else{
          // so that wishlist can update items on newly loaded pages
          if(window.currentWishlist) {
            const event = new CustomEvent('newCollectionPageLoaded',{detail:{wishlistPage: false, allVariantIds: window.currentWishlist.allVariantIds}});
            document.dispatchEvent(event);
          }
        }
      }
    });
  }
}
customElements.define('load-prev-pages', LoadPrevPages);


function initTrustpilotReviewsLink() {
  const trustpilotReviewsLink = document.getElementById('trustpilot-reviews-link');
  if(trustpilotReviewsLink) {
    trustpilotReviewsLink.addEventListener('click', (e) => {
      const linkedId = e.target.getAttribute('href').substring(1);
      const reviews = document.getElementById(linkedId);
      if(reviews) {
        reviews.getElementsByTagName('details')[0].setAttribute('open','open');
      }
    })
  }
}
document.addEventListener('DOMContentLoaded', function() {
  initTrustpilotReviewsLink();
});


class LocalizationModalForm extends HTMLElement {
  constructor() {
    super();
    this.form = this.querySelector('.localization-modal');
    this.radios = this.getElementsByClassName('localization-modal__radio');
    this.availableCountries = [];
    this.cookieName = 'locationChosen';
    this.seenInVisualEditor = !!document.querySelector('.body--visual-editor');
    this.isEurSite = !!document.querySelector('.body--is-eur-site');
    const self = this;
    for (let i = 0; i < this.radios.length; i++) {
        const element = this.radios[i];
          element.addEventListener('click',function() {
            if(this.checked) {
              if(!this.seenInVisualEditor) {
                self.setLocalizationCookie(this.value);
              }
              this.form.submit();
            }
        })
    }

    const currentCountryCodeElement = document.querySelector('[data-current-localization]');
    if(!currentCountryCodeElement) {return;}
    this.currentCountryCode = currentCountryCodeElement.getAttribute('data-current-localization');

    const countryCodeElements = document.querySelectorAll('[data-localization-item-name]');
    if(!countryCodeElements.length) {return;}
    for (let i = 0; i < countryCodeElements.length; i++) {
      const element = countryCodeElements[i];
      this.availableCountries.push(element.getAttribute('data-localization-item-name'))
    }

    if(!this.seenInVisualEditor){
      this.handleModalAndCookie();
    }
  }

  setLocalizationCookie(cookieValue){
    const CookieDate = new Date();
    CookieDate.setFullYear(CookieDate.getFullYear() + 1);
    if(!this.availableCountries.includes(cookieValue)){
      cookieValue='EUR';
    }
    document.cookie = `${this.cookieName}=${cookieValue};path=/;expires=${CookieDate.toUTCString()};`;
  }

  getInfoFromCookie(){
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${this.cookieName}=`);
    let returnValues = {'cookieHasValidCountry':false,'cookieHasCurrentCountry':false}
    if (parts.length === 2) {
      const cookieValue = parts.pop().split(';').shift();
      if(this.availableCountries.includes(cookieValue)){
        returnValues.cookieHasValidCountry = true;
        if(cookieValue === this.currentCountryCode){
          this.setLocalizationCookie(cookieValue);//renew
          returnValues.cookieHasCurrentCountry = true;
        }
      }
    }
    return returnValues;
  }

  handleModalAndCookie(){
    const infoFromCookie = this.getInfoFromCookie();

    const modal =  document.getElementById('PopupModal-location');
    if (!modal){return;}
    // show popop if: there's no preferredCountry cookie set and it is on the EUR site, OR if there's a preferredCountry cookie set, and its value is different from the current site's country.
    if((this.isEurSite && !infoFromCookie.cookieHasCurrentCountry) || (infoFromCookie.cookieHasValidCountry && !infoFromCookie.cookieHasCurrentCountry)){
      modal.show();
    }
    // set preferred cookie if: current site is DK, SE, NO, UK, or DE, and the use does not already have a cookie.
    if(!this.isEurSite && !infoFromCookie.cookieHasValidCountry) {
      this.setLocalizationCookie(this.currentCountryCode)
    }
  }
}
customElements.define('localization-modal-form', LocalizationModalForm);


class AccordionComponent extends HTMLElement {
  constructor() {
      super();
      this.blockMedia = this.querySelectorAll('.accordion__media');
      for (let i = 0; i < this.blockMedia.length; i++) {
        const element = this.blockMedia[i];
        element.addEventListener('load', (e) => {
          if(e.target.previousElementSibling){
            e.target.previousElementSibling.classList.add('hidden');
          }
        });
      }
  }
}
customElements.define('accordion-component', AccordionComponent);

class CountdownNumber extends HTMLElement {
  constructor() {
      super();
      this.timeLeft = parseInt(this.dataset.timeLeft, 10);
      this.labels = this.dataset.labels.split(',');
      this.widthHoldingAncestor = this.closest('.grid-component__item-link');
      this.elems = {};

      this.countDown();
      this.sizeCalculations();
      window.addEventListener('resize', debounce(() => {
        this.sizeCalculations();
      },150));
  }
  sizeCalculations() {
    this.setFontSizeClasses();
  }
  setFontSizeClasses() {
    const ancestorWidth = this.widthHoldingAncestor.offsetWidth;
    const classPrefix = 'countdown-number--cell-min-'
    const arrSizes = [1300,1100,900,700,500,400]
    for (let j = 0; j < arrSizes.length; j++) {
      const currSize = arrSizes[j];
      this.classList.remove(classPrefix+currSize);
    }

    for (let i = 0; i < arrSizes.length; i++) {
      const currSize = arrSizes[i];
      if(ancestorWidth > currSize) {
        this.classList.add(classPrefix+currSize)
        break;
      }

    }
  }
  countDown() {
    if(this.timeLeft > 0) {

      const partsTimeLeft = this.getPartsTimeLeft();
      const formattingInfo = this.getFormattingInformation(partsTimeLeft);

      let htmlToRender = '';

      for (const [key, value] of Object.entries(formattingInfo)) {
        htmlToRender += this.getPartHtml(key, value);
      }
      this.innerHTML = htmlToRender;
      this.timeLeft -= 1;
      setTimeout(() => {this.countDown();},1000);
    } else {
      location.reload();
    }
  }
  getFormattingInformation(partsTimeLeft) {
    const formatDays = this.formatNumberZeros(partsTimeLeft.days);
    const formatHours = this.formatNumberZeros(partsTimeLeft.hours);
    const formatMinutes = this.formatNumberZeros(partsTimeLeft.minutes);
    const formatSeconds = this.formatNumberZeros(partsTimeLeft.seconds);

    const obj = {
      days: {
        showColon:true,
        value:formatDays
      },
      hours: {
        showColon:true,
        value:formatHours
      },
      minutes: {
        showColon: true,
        value:formatMinutes
      },
      seconds: {
        showColon:false,
        value: formatSeconds
      }
    };

    return obj;
  }
  getPartHtml(partName, partData) {
    const objPartValues =  {days:0,hours:1,minutes:2,seconds:3};
    const unitLabel = this.labels[objPartValues[partName]];

    const main =  `<fieldset class="countdown-number__interval-part">
      <div class="countdown-number__interval-holder">
        <span class="countdown-number__unit"><legend class="countdown-number__unit-inner">${unitLabel}</legend></span>
        <span class="countdown-number__number"><span class="countdown-number__number-inner">${partData.value}</span></span>
      </div>
    </fieldset>`;
    const colon = partData.showColon ? '<span class="countdown-number__colon">:</span>' : '';
    return main + colon;
  }
  formatNumberZeros(number) {
    let newNumber = number;
    if(number <= 9)
    {
      newNumber = '0' + number;
    }

    return newNumber;
  }
  getPartsTimeLeft() {
    let intervalSecondsLeft = this.timeLeft;

    const intervalDays= Math.floor(intervalSecondsLeft / (60 * 60 * 24));
    const daysInSecs = intervalDays * (60 * 60 * 24);

    intervalSecondsLeft= intervalSecondsLeft - daysInSecs;

    const intervalHours=  Math.floor(intervalSecondsLeft / (60 * 60));
    const hoursInSecs = intervalHours * (60 * 60);

    intervalSecondsLeft= intervalSecondsLeft - hoursInSecs;

    const intervalMinutes=  Math.floor(intervalSecondsLeft / (60));
    const minutesInSecs = intervalMinutes * (60);

    intervalSecondsLeft= intervalSecondsLeft - minutesInSecs;

    const obj = {
      days: intervalDays,
      hours: intervalHours,
      minutes: intervalMinutes,
      seconds: intervalSecondsLeft
    }

    return obj;
  }
}
customElements.define('countdown-number', CountdownNumber);

class UspComponent extends HTMLElement {
  constructor() {
    super();

    var template = document.getElementById('usp-component-template');
    var clonedTemplate = template.content.cloneNode(true);
    this.appendChild(clonedTemplate);
  }
}

customElements.define('usp-component', UspComponent);



if (!customElements.get('transparent-site-header')) {
  customElements.define('transparent-site-header', class TransparentSiteHeader extends HTMLElement {
    constructor() {
      super();
    }
  });
}

document.body.addEventListener('click', (evt) => {
  const target = evt.target;
    if (target.id === 'js-mobile-facets__open') {
      const headerDrawer = document.querySelector('header-drawer');
      const currentUrl = location.pathname;
      headerDrawer.openSpecificNavigationItem(currentUrl);
    }
})


customElements.define('announcement-container', class AnnouncementContainer extends HTMLElement {
  constructor() {
      super();
      this.announcementShowing = 'standard';
      if(this.classList.contains('announcement-bar__outer--marquee-style')) {
        this.announcementShowing = 'marquee';
      } else if(this.classList.contains('announcement-bar__outer--fade-style')) {
        this.announcementShowing = 'fade';
      }
      this.initAnnouncementCalculation();
  }

  initAnnouncementCalculation(){
    window.addEventListener('resize', debounce(() => {
      this.setAnnouncementHeightCustomPropCss();
    },150));
    this.setAnnouncementHeightCustomPropCss();
  }

  setAnnouncementHeightCustomPropCss(){
    const announcementBar = document.getElementById('shopify-section-announcement-bar');
    const annHeight = announcementBar.offsetHeight;
    document.documentElement.style.setProperty('--transparent-announcement-height', `${annHeight}px`);
  }

  connectedCallback() {
    if(this.announcementShowing === 'marquee') {
      this.marqueeInit();
    }

    if(this.announcementShowing === 'fade') {
      const childCount = this.querySelectorAll('.announcement-bar').length;
      if(childCount > 1) {
        this.fadeInit();
      }
    }
  }

  disconnectedCallback() {
    if(this.announcementShowing === 'fade') {
      clearInterval(this.setSlider);
    }
  }

  fadeInit() {
    const durationSetting = parseInt(this.getAttribute('data-fade-duration'),10);
    this.duration = durationSetting * 1000;
    this.setSlider = setInterval(() => {this.fadeSlider(this)}, this.duration);
    this.addEventListener('mouseleave', () => {
      this.setSlider = setInterval(() => {this.fadeSlider(this)}, this.duration);
    });
    this.addEventListener('mouseenter', () => {
      clearInterval(this.setSlider);
    });
  }
  fadeSlider(_self) {
    const activeClass = 'announcement-bar--fade-active';
    const current = _self.querySelector(`.${activeClass}`);
    var next = current.nextElementSibling === null ? _self.querySelector('.announcement-bar:first-child') : current.nextElementSibling;
    next.classList.add(activeClass);
    current.classList.remove(activeClass);
  }

  marqueeInit() {
    this.speed = parseInt(this.getAttribute('data-marquee-speed'),10);
    this.allItemsButLast = this.querySelectorAll('.announcement-bar:not(:last-child)');
    window.addEventListener('resize', debounce(() => {
      this.marqueeCalculations();
    },150));
    this.marqueeCalculations();
  }

  marqueeCalculations() {
    //set width
    let marqueeWidth = 0;
    this.allItemsButLast.forEach(x => {
      marqueeWidth += x.offsetWidth;
    });
    this.style.setProperty('--marqueeWidth', `${-1 * marqueeWidth}px`);

    //set duration
    //if speed setting === 1, the speed will be 12px pr second
    const marqueeDuration = marqueeWidth / (12 * this.speed);
    this.style.setProperty('--marquee-duration', `${marqueeDuration}s`);
  }

});

customElements.define('safari-helper', class SafariHelper extends HTMLElement {
  constructor() {
    super();
    if(window.browserIsSafari) {
      this.makeSafariRethinkSize();
    }
  }

  makeSafariRethinkSize() {
    //safari does not show images that are ajax-loadet in a good quality
    const images = this.parentElement.querySelectorAll('img.ajax-loadet');
    for (let i = 0; i < images.length; i++) {
        const img = images[i];
        const outer = img.outerHTML;
        img.outerHTML = outer;
    }
  }
});

const btnBarcodes = document.querySelectorAll('.button--barcode, #js-barcode-icon-drawer');
for (let m = 0; m < btnBarcodes.length; m++) {
  const btnBarcode = btnBarcodes[m] ;
  btnBarcode.addEventListener('click',function(e){
    if(e.target.id === 'js-barcode-icon-drawer') {
      const barcodeHeader2 = document.querySelector('.js-header__barcode');
      if(barcodeHeader2) {
        setTimeout(function(){
          barcodeHeader2.open()
        })
      }
    }else{
      e.stopPropagation();
      const barcodeIcon = document.querySelector('.header__icon--barcode');
      if(barcodeIcon) {
        const anEvent = new Event('click', { bubbles: true });
        setTimeout(function(){
          barcodeIcon.dispatchEvent(anEvent);
        })
      }
  }
})
}

function reorderCounterReplacement(newNumber,elem){
  const txt = elem.textContent;
  const txtSplit = txt.split(' ');
  txtSplit.splice(0, 1, newNumber);
  elem.textContent =  txtSplit.join(' ');
  elem.classList.add('visibility-visible')
}

const reorderAmountElem = document.getElementById('reorder-amount');
if(reorderAmountElem) {
  const reorderAmount = reorderAmountElem.getAttribute('data-amount');

  const number1 = document.querySelectorAll('.reorder .mobile-facets__count');
  const number2 = document.querySelectorAll('.reorder .product-count__text');

  for (let i = 0; i < number1.length; i++) {
    reorderCounterReplacement(reorderAmount, number1[i])
  }
  for (let j = 0; j < number2.length; j++) {
    reorderCounterReplacement(reorderAmount, number2[j])
  }
}